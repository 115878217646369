import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Test Week Starts Today!`}</strong></p>
    <p>{`Skill Test: Max Distance Handstand Walk, 3 attempts`}</p>
    <p>{`Bench Press 5×1 to 1RM`}</p>
    <p>{`Wt’d Strict Pullups 5×1 to 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for total reps of:`}</p>
    <p>{`Max HSPU’s (strict if possible)`}</p>
    <p>{`Max Ring Rows (chest under rings, legs straight)`}</p>
    <p>{`rest 2:00 between rounds.`}</p>
    <p><em parentName="p">{`*`}{`compare to 6/4/18`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open wod 19.3 will be announced tonight at 8:00pm at:
games.crossfit.com.  It will be Friday’s class wod.  We’ll be throwing
down again Friday between 4:30-6:30.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free CrossFit class at 9:00 & 10:00am so bring
a friend!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit the Ville day at Climb Nulu is this Saturday at 11:30.  We
have a sign up sheet by the white board so let us know if you can make
it.  If you have any questions email Daniel.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Next Pop Up Mobility classes with Glenn be this Sunday, March
10th, at 12:00am.  This is free for all members, \\$10 for anyone else,
so pop in if you can make it!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      